<template>
	<form
		class="address-popup"
		@submit.prevent.stop="tryOpenMap()"
	>
		<div class="address-popup__body">
			<div class="address-popup__header">
				<span class="address-popup__title">Адрес доставки</span>
				<div
					class="address-popup__close"
					@click="closeAddressPopup"
				>
					<img
						src="~@/assets/img/close.svg"
						alt="close icon"
					>
				</div>
			</div>
			<div class="address-popup__search">
				<label
					for=""
					class="search-label delivery-search"
					:class="{'search-label_focused' : focused}"
					@click="$refs.addressSearch.focus()"
				>
					<div class="search-icon">
						<svg
							width="18"
							height="23"
							viewBox="0 0 18 23"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.00019 0C7.22081 0.00093786 5.48163 0.52919 4.00231 1.51803C2.523 2.50687 1.36991 3.91194 0.688716 5.55577C0.00751764 7.19959 -0.171239 9.00842 0.175026 10.7538C0.52129 12.4991 1.37704 14.1027 2.63419 15.362C4.26419 16.992 8.10019 19.35 8.32719 21.827C8.36119 22.197 8.63019 22.5 9.00019 22.5C9.37019 22.5 9.64019 22.197 9.67319 21.827C9.90019 19.35 13.7332 16.996 15.3622 15.367C16.6208 14.1082 17.478 12.5046 17.8254 10.7587C18.1727 9.01289 17.9947 7.20326 17.3138 5.55857C16.6329 3.91388 15.4797 2.50799 13.9999 1.5186C12.5201 0.529208 10.7803 0.000741466 9.00019 0ZM9.00019 12.079C8.59585 12.079 8.19547 11.9994 7.82191 11.8446C7.44834 11.6899 7.10892 11.4631 6.82301 11.1772C6.5371 10.8913 6.3103 10.5518 6.15556 10.1783C6.00083 9.80472 5.92119 9.40434 5.92119 9C5.92119 8.59566 6.00083 8.19528 6.15556 7.82172C6.3103 7.44816 6.5371 7.10873 6.82301 6.82282C7.10892 6.53691 7.44834 6.31011 7.82191 6.15538C8.19547 6.00064 8.59585 5.921 9.00019 5.921C9.81679 5.921 10.5999 6.24539 11.1774 6.82282C11.7548 7.40024 12.0792 8.1834 12.0792 9C12.0792 9.8166 11.7548 10.5998 11.1774 11.1772C10.5999 11.7546 9.81679 12.079 9.00019 12.079Z"
								fill="#FD470E"
							></path>
						</svg>
					</div>
					<span
						class="search-main-popup_city"
						v-if="$store.state.currentCity"
					></span>
					<div
						class="search-main-popup_clear-button"
						@click="clearAddress()"
					>
						<svg
							width="24"
							height="24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g opacity=".6">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M14.7 13.3a1 1 0 1 1-1.4 1.4L12 13.42l-1.3 1.3a1 1 0 0 1-1.4 0 1 1 0 0 1 0-1.42L10.58 12l-1.3-1.3a1 1 0 1 1 1.42-1.4L12 10.58l1.3-1.3a1 1 0 1 1 1.4 1.42L13.42 12l1.3 1.3ZM12 2a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Z"
									fill="#979797"
								/>
								<mask
									id="a"
									style="mask-type:alpha"
									maskUnits="userSpaceOnUse"
									x="2"
									y="2"
									width="20"
									height="20"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M14.7 13.3a1 1 0 1 1-1.4 1.4L12 13.42l-1.3 1.3a1 1 0 0 1-1.4 0 1 1 0 0 1 0-1.42L10.58 12l-1.3-1.3a1 1 0 1 1 1.42-1.4L12 10.58l1.3-1.3a1 1 0 1 1 1.4 1.42L13.42 12l1.3 1.3ZM12 2a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Z"
										fill="#fff"
									/>
								</mask>
							</g>
						</svg>
					</div>
					<input
						type="text"
						ref="addressSearch"
						id="addressSuggest"
						name="address-popup"
						class="search input search__map_address-input"
						autocomplete="off"
						:value="deliveryAddress"
						@input="changeAddress($event)"
						@keypress.enter="tryOpenMap()"
						@focus="placeholderHideOnFocusSearchInput()"
						@blur="placeholderShowOnBlurSearchInput()"
					>
					<label
						for="suggestPopup"
						class="search__map_address-label"
						:class="{'search__map_address-label_active': deliveryAddress !== ''}"
					>
						{{ focused || deliveryAddress !== '' ? 'Ваш адрес' : 'Куда доставить?' }}
					</label>
					<span
						class="search-main-popup__error"
						v-if="buildingRequired"
					>Уточните номер дома</span>
				</label>
			</div>
			<div class="address-popup__suggestions">
				<div class="address-popup__suggestions-inner">
					<div
						v-for="(suggestItem, num) in returnCutSuggest"
						:key="num"
						class="address-popup__suggestion"
						:class="{'address-popup__suggestion_active': suggestItem.active}"
						@click="checkSelectedSuggestion(suggestItem, onlyClosePopup)"
					>
						<div class="address-popup__city">{{ suggestItem.value }}</div>
					</div>
				</div>
			</div>
			<button
				class="address-popup__select-menu"
				@click="tryOpenMap()"
			>Везите сюда
			</button>
		</div>
		<Popup :visible.sync="showNoRestaurantsPopup">
			<NoRestaurants
				@changeAddress="showAddressMap()"
				@closePopup="showNoRestaurantsPopup = false"
			/>
		</Popup>
	</form>
</template>

<script>
import search from "@/mixins/search";
import NoRestaurants from '@/components/popup/NoRestaurants.vue'
import {mapGetters} from "vuex";

export default {
	mixins: [search],
	props: ['onlyClosePopup'],
	components:
		{
			NoRestaurants
		},
	computed:
		{
			...mapGetters({
				deliveryAddress: 'map/getDeliveryAddress'
			}),
		},
	data: () => ({
		buildingRequired: false,
		showNoRestaurantsPopup: false,
		addressMapPopupName: 'AddressMapPopup',
	}),

	methods: {

		closeAddressPopup()
		{
			this.$emit('closePopup');

			if (!this.onlyClosePopup) this.handleCurrentAddress();
		},

		async tryOpenMap()
		{
			let geo = (await ymaps.geocode(this.$store.state.currentCity + ', ' + this.deliveryAddress))
				.geoObjects.get(0);

			let precision = geo.properties.get('metaDataProperty.GeocoderMetaData.precision');
			this.$store.dispatch('map/setCurrentAddressPrecision', precision);

			if (precision === 'street')
			{
				this.buildingRequired = true;
				return;
			}

			this.buildingRequired = false;
			this.$store.commit('map/SET_MAP_UPDATE', true);
			this.$store.dispatch('openPopup', 'AddressMapPopup');
			this.suggestItems = false;
			this.$emit('closePopup');
		},

		async handleCurrentAddress()
		{
			let res = await this.setRestaurants();

			switch (res)
			{
				case 'noRest':
					await this.$store.dispatch('changeInvalidAddress', this.address);
					await this.$store.dispatch('openPopup', this.addressMapPopupName);
					this.showNoRestaurantsPopup = true;

					break;
				case 'oneRest':
					this.showNoRestaurantsPopup = false;
					await this.$store.dispatch('openPopup', null);
					if (this.$route.path !== "/menu") this.$router.push('/menu');
					break;
				case 'severalRest':
					this.showNoRestaurantsPopup = false;
					await this.$store.dispatch('openPopup', this.selectRestauantPopupName);
					break;
				case false:
					await this.$store.dispatch('openPopup', this.addressMapPopupName);
					this.showNoRestaurantsPopup = true;
					break;
			}
		},

		scrollHandler(e)
		{
			this.$refs.addressSearch.blur();
			this.focused = false;
		},

		clearAddress()
		{
			this.$store.dispatch('map/deliveryAddressAction', '');
		},

		/**
		 * Отркывает попап выбора адреса на карте
		 */
		showAddressMap()
		{
			this.$store.dispatch("openPopup", this.addressMapPopupName);
		},
	},

	beforeMount()
	{
		// Запрос зон
		this.getZones();
	},

	async mounted()
	{

		//document.querySelector('.header-main').style.display = 'none';

		window.addEventListener('scroll', this.scrollHandler);
		this.$refs.addressSearch.focus();
		this.focused = true;

		if (this.deliveryAddress) this.suggestItems = await this.getSuggest() || [];
	},

	destroyed()
	{
		window.removeEventListener('scroll', this.scrollHandler);
		document.querySelector('.header-main').style.display = 'block';
	}
}
</script>

<style lang="scss">

.search-main-popup__error
{
	font-size: 12px;
	margin-top: -6px;
}

.address-popup__select-menu
{
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 80px;
	margin: 0 auto;
	height: 48px;
	width: calc(100% - 72px);
	outline: none;
	border: none;
	font-size: 14px;
	font-family: $mainFontBoldNew;
	background: $red;
	color: #fff;
	border-radius: 8px;
}

.search-main-popup_clear-button
{
	position: absolute;
	right: 8px;
	top: 12px;
	z-index: 5;

	@media (min-width: 568px)
	{
		display: none;
	}
}

header .address-popup
{height: calc(100% - 64px);}

header .address-popup__body
{height: calc(100svh - 64px);}

.address-popup
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	font-family: $mainFontNew;
	font-style: normal;

	&__suggestions
	{
		width: 100%;
		flex: 1 1 auto;
		overflow: hidden;
		position: relative;
		margin-bottom: 30px;

		&-inner
		{
			position: absolute;
			height: 100%;
			width: 100%;
			overflow: auto;
		}
	}

	&__suggestion
	{
		padding: 9px 0;
		font-family: $mainFontNew;

		&:not(:last-child)
		{
			border-bottom: 1px solid $greyBorder;
		}
	}

	&__city
	{
		font-family: $mainFontMediumNew;
		font-size: 16px;
		font-weight: 500;
		color: $textColor;

		& span
		{
			color: $green;
		}
	}

	&__region
	{
		font-family: $mainFontNew;
		color: $greyLightText;
		font-size: 11px;
		line-height: 14px;
	}

	&__body
	{
		height: 100svh;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #fff;
		width: 100%;
		padding: 20px 32px;
		transition: all .2s ease-in-out;
		min-height: 225px;
	}

	&__title
	{
		font-family: $mainFontBoldNew;
		font-weight: bold;
		font-size: 20px;
	}

	&__header
	{
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
		width: 100%;
		flex: 0 0 auto;
	}

	&__search
	{
		width: 100%;
		flex: 0 0 auto;
	}

	.search-label
	{
		margin-bottom: 7px;

		&_focused, &:hover
		{
			border: 1px solid #40AE49 !important;
			background: #fff;
		}
	}
}

@media (max-width: 374px)
{
	.address-popup
	{

		&__city
		{
			font-size: 14px;
			line-height: 20px;
		}

		&__suggestion
		{
			padding: 8px 0;
		}

		.search-label
		{
			margin-bottom: 8px;

			.search__map_address-input
			{
				font-size: 14px;
				height: 20px;
			}

			.search__map_address-label_active
			{
				top: 16px;
			}
		}


		&__body
		{
			padding: 20px 24px;
		}

		&__header
		{
			margin-bottom: 8px;
		}

		&__title
		{
			font-size: 18px;
			line-height: 28px;
			height: fit-content;
		}
	}
}
</style>
